import React, { useState } from 'react';

const FileUploadComponent = () => {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [differences, setDifferences] = useState(null);

  const handleFileChange1 = e => setFile1(e.target.files[0]);
  const handleFileChange2 = e => setFile2(e.target.files[0]);

  const handleCompare = async () => {
    if (!file1 || !file2) {
      return;
    }

    const formData = new FormData();
    formData.append('file1', file1);
    formData.append('file2', file2);

    try {
      const response = await fetch('http://localhost:3001/api/compara/compare-files', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setDifferences(data.differences);
      } else {
      }
    } catch (error) {
    }
  };

  return (
    <div>
      <input type="file" accept=".txt" onChange={handleFileChange1} />
      <input type="file" accept=".txt" onChange={handleFileChange2} />
      <button onClick={handleCompare}>Compare Files</button>

      {differences && (
        <div>
          <h3>Differences</h3>
          {differences.map((diff, index) => (
            <div key={index}>
              <p>
                Line Number:
                {diff.lineNumber}
              </p>
              {diff.modifiedFields.map((field, i) => (
                <div key={i}>
                  <p>
                    Field:
                    {field.fieldName}
                  </p>
                  <p>
                    Expected:
                    {field.expectedValue}
                  </p>
                  <p>
                    Actual:
                    {field.actualValue}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FileUploadComponent;
